export const initialSalesState = {
  cstm_id: null,
  fk_bill_type: null,
  fk_customer: null,
  customer_name: null,
  customer_address: null,
  customer_mobile: null,
  documents_no: null,
  careof_user: null,
  payment_type: "CASH",
  order_no: null,
  bill_no: null,
  created_at: null,
  rate_types: "RETAIL_RATE",
  date: new Date().toISOString(),
  tax_gst: null,
  interstate: false,
  reverse_charge: false,
  tax_bill: false,
  total_item: null,
  total_amount: null,
  total_amount2: null,
  bank_amount: null,
  fk_bank: null,
  gst_in: null,
  item: null,
  total_discount: null,
  discount: null,
  roundoff: null,
  paid_cash: null,
  change_due: null,
  vehicle_no: null,
  total_margin: null,
  total_items: null,
  total_disc: null,
  total_value: null,
  total_qty: null,
  driver: null,
  poject: null,
  address: null,
  bank: null,
  total_cgst: null,
  total_scGst: null,
  return_value: null,
  return_cgst_sgst: null,
  transfer_account: null,
  delivery_address: null,
};

export const initialTableItemState = {
  cstm_id: null,
  item_name: null,
  fk_item: null,
  code: null,
  quantity: 0.0,
  fk_unit: null,
  gross: 0.0,
  transaction_unit: null,
  rate: 0.0,
  sales_rate: 0.0,
  margin: 0.0,
  cost: 0.0,
  total: 0.0,
  sgst: 0.0,
  cgst_or_igst: 0.0,
  tax_gst: 0.0,
  value: 0.0,
  sale_discount: 0.0,
  discount_1_percentage: 0.0,
  discount_1_amount: 0.0,
};
