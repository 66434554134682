export const initialItemData = {
  code: null,
  hsn: null,
  name: null,
  second_name: null,
  types: "PRODUCT",
  category: null,
  sub_category: null,
  company: null,
  size: null,
  color: null,
  group: null,
  tax_group: null,
  rack: null,
  unit: null,
  transaction_unit: null,
  mrp_rate: null,
  retail_rate: null,
  wholesale_rate: null,
  super_wholesale_rate: null,
  quotation_rate: null,
  rent: null,
  rent_type: null,
  purchase_rate: null,
  cost: null,
  margin: null,
  tax_gst: null,
  cess_1: null,
  cess_2: null,
  purchase_discount: null,
  sale_discount: null,
  unload_charge: null,
  load_charge: null,
  point: null,
  commission: null,
  damage: null,
  qty_in_box: null,
  open_stock: null,
  role: null,
  damage_cost: null,
  blocked: false,
  tax_inclusive: false,
  manuel_qty_in_bc: false,
  rent_item: false,
  gate_pass: false,
  barcode: null,
};

export const initialBarcode = {
  B_id: null,
  B_code: null,
  B_expiry: null,
  B_rate: null,
  B_mrp: null,
};

export const initalUnitConv = {
  U_id: null,
  U_unit: null,
  U_qty: null,
  U_rate: null,
  U_mrp: null,
};